<template>
<div>	
	<div class="wrong-box">		
        <div class="wrong-img">           			
			<div class="wrong-tit">
				<span class="wrong403">403</span><span class="chucuo">出错啦！</span>
				<p class="wrong-p">没有访问权限!</p>
			</div>						
        </div>
	</div>                	
</div>
</template>

<script>
export default {
	name:'page404',
	components: {
		
	},
	data(){
		return{			
			timeBack:5
		}			
	},
	mounted(){
		this.timeback()
	},
	methods: {    		
	},
}
</script>
<style>
html,body{
	background-color: #fff !important;
}
.wrong-box{    
	background-color: #fff !important;	
}
.wrong-img{
	width: 620px;
	height: 656px;
	position: relative;
	background: url('https://video.cicg.com.cn/image/default/D429D0FC8DE6472A9DFBCED8606F9535-6-2.jpg') center center no-repeat;
	margin: 50px auto;
}
.wrong-tit{
	position: absolute;
	top: 40%;
	left: 25%;
}
.wrong403{
	font-size: 100px;
	color: red;
}
.chucuo{
	font-size: 50px;
}
.wrong-p{
	font-size: 20px;
	padding: 10px 0;
}
.comeback{
	width: 150px;
	height: 40px;
	background: red;
	text-align: center;
	line-height: 40px;
	position: absolute;
	bottom: 10%;
	left: 50%;
	margin-left: -75px;
	border-radius: 5px;
}
.comeback a{
	display: block;
	width: 100%;
	height: 100%;
	color: #fff;
}
.timeback{
	width: 100px;
	text-align: center;
	position: absolute;
	bottom: 5%;
	left: 50%;
	margin-left: -50px;
}
</style>
